import React, { useState } from 'react';
import './leaders-stats.css';
import leaders from './data/leaders.json';

const orderedLeaders = Object.values(leaders).sort((l1, l2) => l1.name.localeCompare(l2.name));

const LeadersStats = () => {
    const [leaderList, setLeaderList] = useState(orderedLeaders);
    
    return (
        <div className="leaders-page">
            <h1>Leaders</h1>
            <ul className="leader-list">
            {leaderList.map((leader) => {
                var icon = '';
                try {
                    icon = require('./icons/leaders/' + leader.icon);
                } catch (error) { }

                return (
                    <li key={ leader.name }>
                    <article className="leader">
                        <header className="leader-header">
                            <h3 className="leader-title">{ leader.name }</h3>
                        </header>
                        <main className="leader-main">
                            <img className="leader-icon" src={ icon } alt={ leader.name } />
                            <section className="leader-powers">
                            </section>
                            {leader.powers.map((power) => {
                                var icon = '';
                                try {
                                    icon = require('./icons/powers/' + power.icon);
                                } catch (error) { }

                                return (
                                    <section className="power">
                                        <section className="power-header">
                                            <img className="power-icon" src={ icon } alt={ power.name } />
                                            <div className="power-name">{ power.name }</div>
                                        </section>
                                        <section className="power-description">
                                            <p dangerouslySetInnerHTML={{ __html: power.description.replace(/\r?\n/g, '<br>') }}/>
                                        </section>
                                    </section>
                                )
                            })}
                        </main>
                    </article>
                    </li>
                )
            })}
            </ul>
        </div>
    );
};

export default LeadersStats;
